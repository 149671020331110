@import '../../../assets/global-style/mixin';

.slider-main {
    max-width: 700px;
    margin: 0 auto;
    padding-bottom: 40px;

    &__slide {
        position: relative;
        padding-bottom: 56.25%;
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
