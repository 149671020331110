@import '../../components/link-icon/link-icon';
@import '../../components/structure/Grid/grid';
@import '../../components/structure/Header/header';
@import '../../components/structure/Navigation/navigation';
@import '../../components/structure/Main/main';
@import '../../components/structure/Wrapper/wrapper';
@import '../../components/structure/Menu/menu';
@import '../../components/structure/Brands/brands';
@import '../../components/structure/Copmany/company';
@import '../../components/structure/Contacts/contacts';
@import '../../components/structure/Footer/footer';
@import '../../components/structure/Breadcrumbs/breadcrumbs';
@import '../../components/buttons/Button/Button.scss';
@import '../../components/images/Icon/icon';
@import '../../components/links/link/link';
@import '../../components/typography/Title/Title';
@import '../../components/other/SearchBlock/searchBlock';
@import '../../components/other/List/list';
@import '../../components/other/ImgBox/imgBox';

.product {
    padding-bottom: 48px;

    &__flex {
        display: flex;
        margin-bottom: 48px;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__previwe {
        flex-shrink: 0;
        width: 35%;
        margin-right: 24px;

        @media screen and (max-width: 767px) {
            width: 50%;
            align-self: center;
            margin-right: 0;
            margin-bottom: 24px;
        }

        @media screen and (max-width: 480px) {
            width: 100%;
            align-self: center;
        }
    }

    &__features {
        flex-grow: 1;
    }

    &__feature {
        display: flex;
        align-items: flex-start;
        font-size: 18px;
        line-height: 140%;

        &-value {
            color: var(--color-blue);
            font-weight: bold;
            margin-right: 4px;
        }

        &--article {
            margin-bottom: 24px;
            font-size: 22px;
        }
    }

    &__brand {
        align-self: center;
        width: 20%;

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }

    &__description {
        margin-bottom: 48px;
        font-size: 18px;
        line-height: 140%;
    }
}
